@use "sass:map";
@use "core/src/stylesheets/base/colors" as color;
@use "core/src/stylesheets/base/variables" as var;

jbd-app-navigation .mat-mdc-nav-list {
  .mat-mdc-list-item,
  .mat-expansion-panel-header {
    height: var.$nav-list-height-dense;
  }

  .mat-mdc-list-item .mdc-list-item__content {
    line-height: 1;
    padding-left: var.$nav-list-item-padding-left;

    .mat-mdc-list-item-title {
      --mdc-list-list-item-label-text-line-height: 1.5;
      --mdc-list-list-item-label-text-tracking: 0;
    }
  }

  .mat-expansion-panel-header .mat-expansion-indicator::after {
    color: map.get(color.$monochrome, 600);
  }

  .mat-expansion-panel-body {
    padding: 0;

    .mat-mdc-list-item .mdc-list-item__content {
      padding-left: var.$nav-list-nested-item-padding-left;
    }
  }
}
