@use "sass:map";
@use "base/colors" as partner-colors;

jbd-ui-stepper-nav {
  & > nav.stepper-desktop-nav > .stepper-nav-button {
    &.is-active {
      color: map.get(partner-colors.$primary, 800) !important;
    }

    .step-circle-wrapper > .step-circle.is-small:not(.is-valid) {
      background-color: map.get(partner-colors.$primary, 200);
      color: map.get(partner-colors.$primary, 800);
    }
  }

  & > nav.stepper-mobile-nav {
    .stepper-spinner-wrapper:before {
      border-color: map.get(partner-colors.$primary, 200);
    }

    .stepper-text-value {
      color: map.get(partner-colors.$primary, 800);
    }
  }
}
