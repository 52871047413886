@use "sass:map";
@use "sass:math";
@use "variables" as calVar;
@use "core/src/stylesheets/base/colors" as color;
@use "core/src/stylesheets/base/variables" as var;

jbd-app-company-location-booking-overview-calendar-widget
  full-calendar.fc.fc-theme-standard {
  .fc-view.fc-list {
    border: none;
  }

  .fc-view.fc-list .fc-list-table {
    // hide all weekdays except mondays
    .fc-list-day {
      &.fc-day:not(.fc-day-mon) {
        display: none;

        & + tr.fc-event {
          display: none;
        }
      }

      &.fc-day.fc-day-mon {
        display: block;

        & + tr.fc-event {
          display: block;
        }
      }
    }

    // surround entry-header and event with border
    .fc-list-day.fc-day.fc-day-mon {
      border-top-left-radius: var.$border-radius-sm !important;
      border-top-right-radius: var.$border-radius-sm !important;
      border: var.$border-width-default solid var(--fc-border-color) !important;
      border-bottom: none !important;
      overflow: hidden;

      & th[scope="colgroup"] {
        border: none !important;
      }

      & + tr.fc-event {
        border-bottom-left-radius: var.$border-radius-sm !important;
        border-bottom-right-radius: var.$border-radius-sm !important;
        border: var.$border-width-default solid var(--fc-border-color) !important;
        border-top: none !important;

        /*& td {
          border: none !important;
        }*/
      }
    }

    // list-entry header
    .fc-list-day.fc-day.fc-day-mon th[scope="colgroup"] {
      display: block;
      text-align: left;

      .fc-list-day-cushion {
        background-color: map.get(color.$monochrome, "white");
        padding: 12px 12px 0;

        jbd-ui-chip {
          transform: translateY(-2px);
        }

        a {
          font-weight: var.$font-weight-regular;
          color: map.get(color.$monochrome, 900);
        }
      }
    }

    // list-entry event
    .fc-list-day.fc-day.fc-day-mon + tr.fc-event {
      display: block;
      text-align: left;
      background-color: map.get(color.$monochrome, "white");
      padding: 8px 12px 12px 12px;
      margin-bottom: math.div(calVar.$calendar-default-gap-width, 2);

      & td.fc-list-event-title {
        display: block;
      }

      // hide event dot
      & td.fc-list-event-graphic {
        display: none;
      }
    }
  }
}
