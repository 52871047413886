@use "sass:map";
@use "variables" as calVar;
@use "core/src/stylesheets/base/colors" as color;
@use "core/src/stylesheets/base/variables" as var;

jbd-app-company-location-booking-overview-calendar-widget
  full-calendar.fc.fc-theme-standard
  .fc-dayGridMonth-view
  table[role="grid"]
  thead[role="rowgroup"]
  tr[role="presentation"]
  th[role="presentation"] {
  border: none;

  th[role="columnheader"] {
    border: none;
    padding-block-end: calVar.$calendar-default-gap-width;

    a {
      color: map.get(color.$monochrome, 700);
      font-weight: var.$font-weight-semibold;
      font-size: var.$font-size-sm;
    }
  }
}
