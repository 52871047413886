@use "sass:map";
@use "base/colors" as partner-colors;
@use "core/src/stylesheets/base/colors" as color;
@use "core/src/stylesheets/base/variables" as var;
@use "core/src/stylesheets/utils/size" as *;
@use "core/src/stylesheets/utils/position" as *;
@use "./variables" as calVar;

// shared
.jbd-calendar-event {
  .event-icon {
    width: calVar.$calendar-event-state-icon-size;
    aspect-ratio: 1;
    border-radius: var.$border-radius-xs;
    background-color: map.get(color.$monochrome, "white");
    position: relative;

    mat-icon.mat-icon {
      @include centered-absolute();

      svg {
        @include size(
          calVar.$calendar-event-state-icon-size * 0.75 !important,
          inherit
        );
      }
    }
  }
}

// shared daygrid
.fc-daygrid-event.jbd-calendar-event {
  padding: calVar.$calendar-event-padding;

  span {
    line-height: 1;
  }
}

// states daygrid
.fc-daygrid-event.jbd-calendar-event {
  &.is-vacation {
    background-color: map.get(partner-colors.$primary, 300);
    border: none;

    .event-icon {
      background-color: map.get(partner-colors.$primary, 200);

      mat-icon.mat-icon {
        color: map.get(partner-colors.$primary, 700);
      }
    }

    span {
      color: map.get(color.$monochrome, 900);
    }
  }

  &.is-booked {
    background-color: map.get(color.$monochrome, "white");
    border: var.$border-width-default solid map.get(color.$monochrome, 300);

    .event-icon {
      background-color: map.get(color.$monochrome, 100);

      mat-icon.mat-icon {
        color: map.get(color.$monochrome, 600);
      }
    }

    span {
      color: map.get(color.$monochrome, 700);

      &.period {
        color: map.get(color.$monochrome, 600);
      }
    }
  }

  &.is-suggested,
  &.is-accepted,
  &.is-sent,
  &.is-received {
    background-color: map.get(color.$monochrome, "white");
    border: var.$border-width-default solid map.get(color.$customer, 800);

    .event-icon {
      background-color: map.get(color.$customer, 200);

      mat-icon.mat-icon {
        color: map.get(color.$customer, 800);
      }
    }

    span {
      color: map.get(color.$monochrome, 900);
    }
  }

  &.is-placed,
  &.is-running,
  &.is-finished {
    background-color: map.get(color.$customer, 800);
    border: none;

    .event-icon {
      background-color: map.get(color.$success, 50);

      mat-icon.mat-icon {
        color: map.get(color.$success, 600);
      }
    }

    span {
      color: map.get(color.$monochrome, "white");
    }
  }
}

// shared list
.fc-list-event.jbd-calendar-event {
  td.fc-list-event-title {
    border-radius: var.$border-radius-sm;
    padding: calVar.$calendar-event-padding;

    span {
      line-height: 1;
    }
  }
}

// states list
.fc-list-event.jbd-calendar-event {
  &.is-vacation td.fc-list-event-title {
    background-color: map.get(partner-colors.$primary, 300);
    border: none;

    .event-icon {
      background-color: map.get(partner-colors.$primary, 200);

      mat-icon.mat-icon {
        color: map.get(partner-colors.$primary, 700);
      }
    }

    span {
      color: map.get(color.$monochrome, 900);
    }
  }

  &.is-booked td.fc-list-event-title {
    background-color: map.get(color.$monochrome, "white");
    border: var.$border-width-default solid map.get(color.$monochrome, 300);

    .event-icon {
      background-color: map.get(color.$monochrome, 100);

      mat-icon.mat-icon {
        color: map.get(color.$monochrome, 600);
      }
    }

    span {
      color: map.get(color.$monochrome, 700);

      &.period {
        color: map.get(color.$monochrome, 600);
      }
    }
  }

  &.is-suggested td.fc-list-event-title,
  &.is-accepted td.fc-list-event-title,
  &.is-sent td.fc-list-event-title,
  &.is-received td.fc-list-event-title {
    background-color: map.get(color.$monochrome, "white");
    border: var.$border-width-default solid map.get(color.$customer, 800);

    .event-icon {
      background-color: map.get(color.$customer, 200);

      mat-icon.mat-icon {
        color: map.get(color.$customer, 800);
      }
    }

    span {
      color: map.get(color.$monochrome, 900);
    }
  }

  &.is-placed td.fc-list-event-title,
  &.is-running td.fc-list-event-title,
  &.is-finished td.fc-list-event-title {
    background-color: map.get(color.$customer, 800);
    border: none;

    .event-icon {
      background-color: map.get(color.$success, 50);

      mat-icon.mat-icon {
        color: map.get(color.$success, 600);
      }
    }

    span {
      color: map.get(color.$monochrome, "white");
    }
  }
}
